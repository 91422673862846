/* You can add global styles to this file, and also import other style files */
HTML, body {
    background: #111111;
    font-size: 18pt;
    line-height: 18pt;
    color: white;
}
.globalContent{
  text-align: center;
}

.genericButton, .loadingButton {
  background: #ad0345;
  color: #fff;
  text-align: center;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 18pt;
  height: 40px;
}

.loadingButton {
  line-height: 14px;
  font-size: 14pt;
  height: 30px;
}

.genericContent {
  color: #909090;
}


.odd {
  background: #818181;
}
.even {
  backgroundd: #363636;
}


.button {
  max-width: 300px;
  width:  100%;
  background: #ad0345;
  color: white;
  border: 2px solid #000;
  border-radius: 10px;
  line-height: 1.2rem;
}

.black {
  background: black;
}
